/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to true if you want to use the splash screen.
  useCustomCursor: false, // Change this to false if you want the good'ol cursor
  googleTrackingID: "UA-174238252-2",
};

//Home Page
const greeting = {
  title: "Hello.",
  title2: "Driss",
  logo_name: "</HLAL>",
  full_name: "Driss HLAL",
  subTitle: "Software Engineer, DevOps Enthusiast. Always learning.",
  resumeLink:
    "https://drive.google.com/file/d/1X40GJYfdX7MSQajv2b6rgERapjcV1Ysl/view",
  mail: "mailto:idrishlal@gmail.com",
};
const contactInfo = {
  phone: "+212 628 546 443",
  email: "idrishlal@gmail.com",
  location: "Casablanca, Morocco",
};
const socialMediaLinks = {
  /* Your Social Media Link */
  github: "https://github.com/HlalDriss",
  linkedin: "https://www.linkedin.com/in/driss-hlal-77143913b/",
  gmail: "idrishlal@gmail.com",
  gitlab: " ",
  facebook: " ",
  twitter: "",
  instagram: "",
};

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Experience working with an agile team using Agile Scrum",
        "⚡ Building responsive website front end using ReactJS and Angular",
        "⚡ Developing mobile applications using React Native and native Android",
        "⚡ Backend development using Spring, Spring Boot, RESTful, Microservice",
      ],
      softwareSkills: [
        {
          skillName: "java",
          fontAwesomeClassname: "fa-FaJava",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Spring",
          fontAwesomeClassname: "simple-icons:spring",
          style: {
            color: "#439743",
          },
        },
        {
          skillName: "Spring Boot",
          fontAwesomeClassname: "simple-icons:springboot",
          style: {
            color: "#439743",
          },
        },
        {
          skillName: "Junit5",
          fontAwesomeClassname: "simple-icons:junit5",
          style: {
            color: "#25A162",
          },
        },
        {
          skillName: "Apache Maven",
          fontAwesomeClassname: "simple-icons:apachemaven",
          style: {
            color: "#C71A36",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "React Query",
          fontAwesomeClassname: "simple-icons:reactquery",
          style: {
            color: "#FF4154",
          },
        },
        {
          skillName: "jest",
          fontAwesomeClassname: "simple-icons:jest",
          style: {
            color: "#C21325",
          },
        },
        {
          skillName: "cypress",
          fontAwesomeClassname: "simple-icons:cypress",
          style: {
            color: "#119EFF",
          },
        },
        {
          skillName: "Angular",
          fontAwesomeClassname: "simple-icons:angular",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },

        {
          skillName: "C#",
          fontAwesomeClassname: "simple-icons:csharp",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "simple-icons:python",
          style: {
            color: "#E94E32",
          },
        },

        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#439743",
          },
        },
        {
          skillName: "GraphQL",
          fontAwesomeClassname: "simple-icons:graphql",
          style: {
            color: "#DE33A6",
          },
        },
        {
          skillName: "Android App Development",
          fontAwesomeClassname: "simple-icons:android",
          style: {
            color: "#3DDC84",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#4479A1",
          },
        },

        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "Eclipse IDE",
          fontAwesomeClassname: "simple-icons:eclipseide",
          style: {
            color: "#2C2255",
          },
        },
        {
          skillName: "Visual studio code",
          fontAwesomeClassname: "simple-icons:visualstudiocode",
          style: {
            color: "#007ACC",
          },
        },
        {
          skillName: "Intellij Idea",
          fontAwesomeClassname: "simple-icons:intellijidea",
          style: {
            color: "#0170FE",
          },
        },
      ],
    },

    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience with essential tools such as Docker, Jenkins, Terraform, Amazon ECS, EC2, and CloudWatch",
        "⚡ Experience building automated CI/CD pipelines with Jenkins",
        "⚡ Experience designing Dockerfiles for containerization",
        "⚡ Experience building scalable infrastructure as code with Terraform",
      ],
      softwareSkills: [
        {
          skillName: "Terraform",
          fontAwesomeClassname: "simple-icons:terraform",
          style: {
            color: "#844FBA",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Jenkins",
          fontAwesomeClassname: "simple-icons:jenkins",
          style: {
            color: "#D24939",
          },
        },
        {
          skillName: "Ansible",
          fontAwesomeClassname: "simple-icons:ansible",
          style: {
            color: "#EE0000",
          },
        },
        {
          skillName: "Jfrog",
          fontAwesomeClassname: "simple-icons:jfrog",
          style: {
            color: "#41BF47",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Sonarqube",
          fontAwesomeClassname: "simple-icons:sonarqube",
          style: {
            color: "#4E9BCD",
          },
        },
      ],
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Abdelmalek Essaadi University",
      subtitle: "Master's degree in Computer Engineering",
      logo_path: "A_E_U.png",
      alt_name: "UAE",
      duration: "2020 - 2022",
      descriptions: [
        "⚡ Studied Advanced Software Development Concepts, Data Management & Warehousing",
        "⚡ Learn Advanced Topics in Cloud Computing, Cloud Architecture along with Serverless Computing",
        "⚡ Worked on projects on courses like Mobile Computing and Web Technologies.",
      ],
      website_link:
        "https://www.fst.ac.ma/site/ing%C3%A9nierie-informatique-m2i",
    },
    {
      title: "Chouaib Doukkali University",
      subtitle:
        "Bachelor's degree in mathematics and computer sciences, option development and databases",
      logo_path: "C_B_U.png",
      alt_name: "UCD",
      duration: "2016 - 2020",
      descriptions: [
        "⚡ I have studied core subjects like Data Structures, DBMS, Networking, Security, etc.",
        "⚡ I have also completed various online courses for ML/DL, Web Development, Mobile App Development, etc.",
        "⚡ I have implemented several projects based on what I've learnt under my Computer Engineering course. ",
        "⚡ Develop knowledge in fundamental computer science, mathematics, physics, languages and communication; ",
      ],
      website_link:
        "https://www.fs.ucd.ac.ma/index.php?page=detail_filiere&id=33",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Learn to code with JavaScript",
      subtitle: "JavaScript",
      logo_path: "openclassrooms.png",
      certificate_link:
        "https://openclassrooms.com/fr/course-certificates/7445895499",
      alt_name: "Javasript",
      color_code: "#7A33FF",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship",
  description:
    "As a DevOps Engineer at DXC Technology, and Previously, as a Programmer Analyst JAVA, and I was intership with same Company. Below are some of my major Experience.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "Devops Engineer",
          company: "DXC Technology",
          company_url: "https://dxc.com/ma/en",
          logo_path: "DXC.png",
          duration: "May 2023 - Current",
          location: "Work From Home",
          description:
            "deploying Java applications on AWS within an agile team using Agile Scrum, using essential tools such as Docker, Jenkins, Terraform, Amazon ECS, EC2 and CloudWatch. Building automated CI/CD pipelines with Jenkins, designing Dockerfiles for containerization, and building scalable infrastructure as code with Terraform ",
          color: "#f10000",
        },
        {
          title: "Software Engineer",
          company: "DXC Technology",
          company_url: "https://dxc.com/ma/en",
          logo_path: "DXC.png",
          duration: "Aug 2022 - May 2023",
          location: "Work From Home",
          description:
            "I worked on projects related to insurance, I used Java and the Spring framework, creating REST API services, writing clean and reusable code, and actively participating in unit testing. Collaborated with business analysts for requirement analysis and high-quality delivery. Presented innovative solutions and effectively communicated with stakeholders. ",
          color: "#f10000",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "React Developer",
          company: "DXC Technology",
          company_url: "https://dxc.com/ma/en",
          logo_path: "DXC.png",
          duration: "February 2022 - Aug 2022",
          location: "Work From Home",
          description:
            "I had an opportunity to modernize user interfaces for the DXC GraphTalk AIA application using ReactJS, Redux, React Query, and JavaScript, and others related to frontend like HTML and CSS and ect.",
          color: "#ee3c26",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. Below are some of my projects.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "driss.back.png",
    description:
      "You can contact me at the places mentioned below. I will try to get back to you as fast as I can. ",
  },
  blogSection: {
    title: "Blogs",
    subtitle: "Coming Soon ...",
    link: "#",
    avatar_image_path: "blogs_image.svg",
  },
};

const projects = {
  data: [
    {
      name: "Tournament Football",
      url: "https://github.com/HlalDriss/tournament.v1",
      description:
        "The Spring-based Football Tournament API streamlines tournament organization, offering real-time updates and secure authentication for efficient football management.",
      languages: [
        {
          name: "Spring Boot",
          iconifyClass: "logos-spring",
        },
        {
          name: "Java",
          iconifyClass: "devicon-java",
        },
        {
          name: "MySQL",
          iconifyClass: "devicon-mysql",
        },
      ],
    },
    {
      name: "Sales Management",
      url:
        "https://github.com/HlalDriss/Gestion-de-vente/tree/master/GestionDeVenteApp",
      description:
        "A Sales Management Application optimizes sales processes, enhancing productivity and revenue growth with lead management,Add Product, Delivery ...",
      languages: [
        {
          name: "Java",
          iconifyClass: "logos-java",
        },
        {
          name: "Spring",
          iconifyClass: "logos-spring",
        },
        {
          name: "MySQl",
          iconifyClass: "logos-mysql",
        },
      ],
    },
    {
      name: "Inventory management",
      url:
        "https://github.com/HlalDriss/Gestion-de-stcok/tree/master/GestionStock",
      description:
        "Inventory management web application using JSF, hibernate, primefaces",
      languages: [
        {
          name: "JAVA",
          iconifyClass: "logos-java",
        },
        {
          name: "Spring",
          iconifyClass: "logos-spring",
        },
        {
          name: "MySQL",
          iconifyClass: "logos-mysql",
        },
      ],
    },
    {
      name: "E-Commerce",
      url:
        "https://github.com/HlalDriss/easy-place-server/blob/master/readme.md#e-commerce-nodejs-backend-api",
      description: "E-Commerce Node.js Backend API",
      languages: [
        {
          name: "EXpressJS",
          iconifyClass: "logos-nodejs",
        },
        {
          name: "Javascript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "Firebase",
          iconifyClass: "logos-firebase",
        },
      ],
    },
    {
      name: "Find Place",
      url: "https://github.com/HlalDriss/Find-places-react-native",
      description:
        "Application mobile React native Find the places in your city",
      languages: [
        {
          name: "React Native",
          iconifyClass: "logos-react",
        },
        {
          name: "Javascript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "Firebase",
          iconifyClass: "logos-firebase",
        },
      ],
    },
  ],
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
  projects,
  contactInfo,
};
